function polyfill(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
}
var customEvent = window.CustomEvent;
if (typeof customEvent !== "function") {
    polyfill.prototype = window.Event.prototype;
    customEvent = polyfill;
}
export default customEvent;
