import { fromEvent as observableFromEvent } from "rxjs";
import { LocaliseService } from "./localise";
var FALLBACK_CSS_CLASS = "with-fallback";
var FALLBACK_CSS_SELECTOR = "img." + FALLBACK_CSS_CLASS;
var FALLBACK_ATTRIBUTE_NAME_PREFIX = "data-fallback-";
var DEFAULT_LAZY_IMG_CLASS = "lazyImg";
var TRANSPARENT_PIXEL_IMG = "/orion/images/trans-pixel.gif";
var noPhotoText = new LocaliseService($("body")).localise("orion.product.card.noimage.alt");
var ImageFallback = (function () {
    function ImageFallback() {
    }
    ImageFallback.process = function ($container) {
        var _this = this;
        $container.find(FALLBACK_CSS_SELECTOR).each(function (i, image) {
            observableFromEvent(image, "error").subscribe(function (event) {
                _this.fallback(image);
            });
            if (!image.complete) {
                observableFromEvent(image, "error").subscribe(function (event) {
                    _this.fallback(image);
                });
            }
            else if (!image.naturalWidth && !image.classList.contains(DEFAULT_LAZY_IMG_CLASS)) {
                _this.fallback(image);
            }
        });
    };
    ImageFallback.fallback = function (image) {
        $.each(image.attributes, function (i, attribute) {
            if (attribute.name.indexOf(FALLBACK_ATTRIBUTE_NAME_PREFIX) === 0) {
                var name_1 = attribute.name.substring(FALLBACK_ATTRIBUTE_NAME_PREFIX.length);
                if (name_1) {
                    var value = image.getAttribute(name_1);
                    if (value !== attribute.value) {
                        image.setAttribute(name_1, attribute.value);
                    }
                }
            }
        });
        var $img = $(image);
        if ($img.hasClass("lazyImgPlaceholder")) {
            $img.removeClass("lazyImgPlaceholder")
                .addClass("js-no-photo-bg")
                .attr("src", TRANSPARENT_PIXEL_IMG)
                .wrap("<span class='js-wrap-no-photo-img'/>")
                .after("<caption>" + noPhotoText + "</caption>");
        }
    };
    return ImageFallback;
}());
export { ImageFallback };
