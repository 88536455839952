import { tap } from "rxjs/operators";
import { ImageFallback } from "./common/imageFallback";
import CustomEvent from "./polyfill/CustomEvent";
import { AjaxService } from "./common/ajax";
import $ from "./common/jquery";
export var CONTENT_LOAD_EVENT = "genericcontentload";
var GenericContentLoader = (function () {
    function GenericContentLoader($sourceElement) {
        this.ajax = AjaxService.getInstance();
        this.document = document;
        this.$sourceElement = $sourceElement;
        this.path = $sourceElement.data("content-url");
        this.ajaxObservable = this.createAjaxObservable();
    }
    GenericContentLoader.prototype.getAjaxObservable = function () {
        return this.ajaxObservable;
    };
    GenericContentLoader.prototype.createAjaxObservable = function () {
        var _this = this;
        return this.ajax.doGetSafe(this.path).pipe(tap(function (data) {
            if (_this.$sourceElement.attr("data-replace-placeholder") === "true") {
                _this.$sourceElement.replaceWith($(data.response));
            }
            else {
                _this.$sourceElement.html(data.response);
            }
            _this.document.dispatchEvent(new CustomEvent(CONTENT_LOAD_EVENT));
            ImageFallback.process(_this.$sourceElement);
        }));
    };
    return GenericContentLoader;
}());
export { GenericContentLoader };
