var LocaliseService = (function () {
    function LocaliseService(container) {
        this.container = container;
    }
    LocaliseService.prototype.localise = function (key) {
        var _this = this;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var message = this.container.find('viator-localised[data-key="' + key + '"]').data("value");
        if (args.length && typeof message === "string") {
            message = message.replace(/{(\d+),choice,([^}]+)}/g, function (_, arg, choices) {
                var discriminant = parseFloat(args[arg]);
                return _this.replaceChoice(choices, discriminant, args);
            });
            message = this.replaceArgs(message, args);
        }
        return message;
    };
    LocaliseService.prototype.replaceArgs = function (message, args) {
        return message.replace(/{(\d+)}/g, function (_, arg) { return args[arg]; });
    };
    LocaliseService.prototype.replaceChoice = function (choices, discriminant, args) {
        var splits = choices.split("|");
        var limits = splits.map(function (choice) { return parseFloat(/^\d+(?:\.\d*)?/.exec(choice)[0]); });
        var formats = splits.map(function (choice) { return /^.*?[#<](.*)$/.exec(choice)[1]; });
        var format = formats[index(discriminant, limits)] || "";
        return this.replaceArgs(format, args);
    };
    return LocaliseService;
}());
export { LocaliseService };
var index = function (input, limits) {
    if (input <= limits[0]) {
        return 0;
    }
    var n = 0;
    while (n + 1 < limits.length) {
        if (input < limits[n + 1]) {
            return n;
        }
        else if (input === limits[n + 1]) {
            return n + 1;
        }
        n++;
    }
    return n;
};
